import { Container, Row, Col } from "react-bootstrap";
import "./cta.scss";

export const Cta = () => (
  <section id="cta" data-aos="fade-up">
    <Container>
      <Row className=" d-flex align-items-center">
        <Col md={8} className="text-center text-md-start">
          <p className="heading">
            Let's collaborate and build something amazing together!
          </p>
        </Col>
        <Col md={4} className="text-center text-md-end">
          <a href="#" className="button button-normal button-yellow my-3">
            Contact Us
          </a>
        </Col>
      </Row>
    </Container>
  </section>
);
