import data from "../../api/logos.json";
import "./logos.scss";

export const Logos = () => {
  return (
    <section id="logos">
      <div className="slider">
        <div className="slide-track2">
          {data.map((x, i) => (
            <div className="slide">
              <img src={x.image} className="slide-logo" alt={x.name} key={i} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
