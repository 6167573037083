import { Container, Row, Col, ListGroup, Form, Button } from "react-bootstrap";

import data from "./../../api/domain-names.json";
import categories from "./../../api/categories.json";

export const DomainName = () => (
  <section id="domain-names">
    <Container>
      <Row>
        <Col md={3}>
          <div class="box">
            <h6>Search Domain Names</h6>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Search domain name"
              />
              <a href="#" class="button button-small button-yellow">
                <ion-icon name="search-outline"></ion-icon>
              </a>
            </div>

            <br />

            <h6>Status</h6>
            <div className="box">
              <Form>
                <Form.Check label="Available Now" />
                <Form.Check label="Temporarily Unavailable" />
              </Form>
            </div>

            <br />

            <h6>Categories</h6>
            <div className="box">
              <Form>
                {categories.domain.map((x, i) => (
                  <Form.Check label={x.name} type="radio" className="mb-3" />
                ))}
              </Form>
            </div>
          </div>
        </Col>
        <Col md={9}>
          <div class="box">
            <ListGroup>
              {data.map((x, i) => (
                <ListGroup.Item key={i}>
                  <Row className="align-items-center">
                    <Col md={5}>
                      <u>{x.domain}</u>
                    </Col>
                    <Col md={2}>
                      <span className="blue">{x.price}</span>
                    </Col>
                    <Col md={3}>{x.status}</Col>
                    <Col md={2}>
                      <Button className="button button-yellow button-small">
                        Buy Now
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
