import {
  Image,
  Container,
  Offcanvas,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";

import "./header.scss";

import Logo from "./logo.png";

export const Header = () => (
  <>
    <Navbar expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="#">
          <Image src={Logo} alt="" fluid />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              Offcanvas
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="mx-auto mb-2 mb-lg-0">
              <Nav.Link href="/">Home</Nav.Link>
              <NavDropdown
                title="Services"
                id={`offcanvasNavbarDropdown-expand-lg`}
              >
                <NavDropdown.Item href="/domain">
                  <Image
                    src="images/icons/icon-hosting.png"
                    className="icon-menu"
                    alt=""
                  />
                  Domain Services
                </NavDropdown.Item>
                <NavDropdown.Item href="/hosting">
                  <Image
                    src="images/icons/icon-storage.png"
                    className="icon-menu"
                    alt=""
                  />
                  Hosting Services
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <Image
                    src="images/icons/icon-development.png"
                    className="icon-menu"
                    alt=""
                  />
                  Web & App Design - Development
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <Image
                    src="images/icons/icon-storage.png"
                    className="icon-menu"
                    alt=""
                  />
                  CMS & Storage Management
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <Image
                    src="images/icons/icon-brand.png"
                    className="icon-menu"
                    alt=""
                  />
                  Brand Agency
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <Image
                    src="images/icons/icon-production.png"
                    className="icon-menu"
                    alt=""
                  />
                  Production House
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <Image
                    src="images/icons/icon-studio.png"
                    className="icon-menu"
                    alt=""
                  />
                  Mini Studio Rental (Podcast & Photo)
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#">Our Projects</Nav.Link>
              <Nav.Link href="#">About Us</Nav.Link>
              <Nav.Link href="#">Articles</Nav.Link>
            </Nav>

            <a className="button button-small button-yellow">Search</a>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  </>
);
