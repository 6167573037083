import { Container, Row, Col } from "react-bootstrap";
import "./statistics.scss";
import data from "./../../api/statistics.json";

export const Statistics = () => (
  <section id="statistics" data-aos="fade-up">
    <Container>
      <Row>
        {data.map((x, i) => (
          <Col key={i} xs={6} md={3} className="text-center">
            <span className="number">{x.qty}</span>
            <p className="number-sub">{x.title}</p>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
);
