import { Container } from "react-bootstrap";

export const Hosting = () => (
  <section>
    <Container className="text-center">
      <p className="heading-small">
        Grab a hosting plan which you'll need anyway
      </p>
      <a href="#" className="button button-normal button-yellow my-3">
        Check Hosting Plans
      </a>
    </Container>
  </section>
);
