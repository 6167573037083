import { Container, Row, Col, Image } from "react-bootstrap";
import data from "./../../api/domain-tips.json";

export const Tips = () => (
  <section id="tips" data-aos="fade-right">
    <Container>
      <p className="heading text-center">
        Confused about choosing a domain name?
        <br />
        Here are some tips to help you
      </p>
      <Row>
        {data.map((x, i) => (
          <Col key={i} sm={6} md={4} className="col-xl">
            <Image src={x.image} className="mb-4" alt="" fluid />
            <p className="heading-small">{x.title}</p>
            <p className="card-text">{x.description}</p>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
);
