import { CardComponent } from "../components/cards/CardComponent";
import { Cta } from "../components/cta/Cta";
import { Footer } from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { Hero } from "../components/hero/Hero";
import { Focus } from "../widgets/focus/Focus";
import { Logos } from "../widgets/logos/Logos";
import { Statistics } from "../widgets/statistics/Statistics";

import why from "./../api/why.json";
import services from "./../api/services.json";

export const Homepage = () => (
  <>
    <Header />
    <Hero type="homepage" />
    <Statistics />
    <CardComponent title="Why collaborate with us?" data={why.site} />
    <Focus />
    <CardComponent title="Our Services" data={services} />
    <Logos />
    <Cta />
    <Footer />
  </>
);
