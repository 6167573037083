import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/styles/global.scss";

import { Routing } from "./Routing";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Routing />);
