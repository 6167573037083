import { Header } from "../components/header/Header";
import { Footer } from "../components/footer/Footer";
import { Cta } from "../components/cta/Cta";
import { CardComponent } from "../components/cards/CardComponent";
import { Hero } from "../components/hero/Hero";
import { FaqComponent } from "../components/faq/FaqComponent";

import { Hosting } from "../widgets/hosting/Hosting";
import { Tips } from "../widgets/tips/Tips";
import { DomainName } from "../widgets/domain-name/DomainName";

import why from "./../api/why.json";
import faq from "./../api/faq.json";

export const Domain = () => (
  <>
    <Header />
    <Hero
      title="Explore the possibilities with the domain name you need"
      subtitle="Domain"
      description="Get your domain starting from Rp 25.000/year"
      button="Search Domain"
      type="domain"
    />
    <Hosting />
    <DomainName />
    <Tips />
    <CardComponent
      title="Get a ton more out of your domain in Headsquarters"
      data={why.domain}
      type="text"
    />
    <FaqComponent
      title="Got questions about domain? Well, we've got answers"
      data={faq.domain}
    />
    <Cta />
    <Footer />
  </>
);
