import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Homepage } from "./pages/Homepage";
import { Domain } from "./pages/Domain";
import { NotFound } from "./pages/NotFound";
import { Hosting } from "./pages/Hosting";

export const Routing = () => (
  <Router>
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Homepage />} />
      <Route path="/domain" element={<Domain />} />
      <Route path="/hosting" element={<Hosting />} />
    </Routes>
  </Router>
);
