import { Container, Row, Col } from "react-bootstrap";
import "./footer.scss";

export const Footer = () => (
  <footer>
    <Container>
      <Row className="d-flex align-items-center">
        <Col lg={6} className="text-center text-lg-start">
          <span>Copyright © 2024 Headsquarters, All Rights Reserved.</span>
        </Col>
        <Col lg={6} className="text-center text-lg-end">
          <a href="#" className="btn btn-link">
            Services
          </a>
          <a href="#" className="btn btn-link">
            Our Projects
          </a>
          <a href="#" className="btn btn-link">
            About Us
          </a>
          <a href="#" className="btn btn-link">
            Services
          </a>
        </Col>
      </Row>
    </Container>
  </footer>
);
