import { Header } from "../components/header/Header";
import { Footer } from "../components/footer/Footer";
import { Cta } from "../components/cta/Cta";
import { CardComponent } from "../components/cards/CardComponent";
import { FaqComponent } from "../components/faq/FaqComponent";
import { Hero } from "../components/hero/Hero";

import why from "./../api/why.json";
import faq from "./../api/faq.json";
import features from "./../api/features.json";
// import { Hero } from "../components/hero/Hero";

export const Hosting = () => (
  <>
    <Header />
    <Hero
      title="Fast and reliable web hosting for all your needs"
      subtitle="Web Hosting"
      description="Ample storage, unlimited bandwidth, unbeatable hosting."
      button="See Plans and Pricing"
    />
    <CardComponent
      title="Web Hosting Features"
      data={features.hosting}
      type="compact"
    />
    <CardComponent
      title="Why our web hosting is best for you"
      data={why.hosting}
      type="text"
    />
    <FaqComponent
      title="Got questions about web hosting? Well, we've got answers"
      data={faq.hosting}
    />
    <Cta />
    <Footer />
  </>
);
