import { Footer } from "../components/footer/Footer";
import { Header } from "../components/header/Header";

export const NotFound = () => (
  <>
    <Header />
    <h1>Page Not Found</h1>
    <Footer />
  </>
);
