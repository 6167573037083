import { Container, Row, Col, Image } from "react-bootstrap";

export const Hero = (props) => {
  if (props.type === "homepage") {
    return (
      <section id="hero">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col lg={10} className="text-center">
              <p className="heading-large">
                Serving and providing the
                <span className="annotation"> digital solutions</span> you need
              </p>
              <p className="grey">
                From consulting to implementation, we offer a complete range of
                digital services tailored to overcome business challenges and
                achieve desired results.
              </p>
              <a href="#" className="button button-normal button-yellow my-3">
                Contact Us
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
  if (props.type === "domain") {
    return (
      <section id="hero">
        <Container>
          <Row>
            <Col lg={7}>
              <p className="heading-smaller mb-0">{props.subtitle}</p>
              <p className="heading mt-1">{props.title}</p>
              {props.paragraph ? <p>{props.description}</p> : ""}

              <Row className="d-flex align-items-center">
                <Col xs={8}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search domain name"
                  />
                </Col>
                <Col xs={3}>
                  <a
                    href="#"
                    className="button button-small button-yellow my-3"
                  >
                    {props.button}
                  </a>
                </Col>
              </Row>
            </Col>
            <Col lg={5}>
              <Image src="images/domain-services.png" alt="" fluid />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
  return (
    <section id="hero">
      <Container>
        <Row>
          <Col lg={7}>
            <p className="heading-smaller mb-0">{props.subtitle}</p>
            <p className="heading mt-1">{props.title}</p>
            {props.description ? <p>{props.description}</p> : ""}
            <a href="#" className="button button-small button-yellow my-3">
              {props.button}
            </a>
          </Col>
          <Col lg={5}>
            <Image src="images/domain-services.png" alt="" fluid />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
