import { Container, Row, Col, Accordion } from "react-bootstrap";

export const FaqComponent = (props) => (
  <section id="faq" data-aos="fade-right">
    <Container>
      <p className="heading text-center">{props.title}</p>
      <Row className="d-flex justify-content-center">
        <Col lg={8} xl={6}>
          <Accordion defaultActiveKey="0">
            {console.log(props.data)}
            {props.data.map((x, i) => (
              <Accordion.Item key={i} eventKey={i}>
                <Accordion.Header>{x.title}</Accordion.Header>
                <Accordion.Body>{x.description}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  </section>
);
