import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Image,
} from "react-bootstrap";

export const CardComponent = (props) => {
  if (props.type === "text") {
    return (
      <section data-aos="fade-right">
        <Container>
          <p className="heading text-center">{props.title}</p>
          <Row>
            {props.data.map((x, i) => (
              <Col sm={6} md={4} xl={3} className="d-flex align-items-stretch">
                <Card key={i}>
                  <CardBody>
                    <CardTitle className="d-flex align-items-center">
                      {x.title}
                    </CardTitle>
                    <CardText>{x.description}</CardText>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    );
  } else if (props.type === "compact") {
    return (
      <section data-aos="fade-right">
        <Container>
          <p className="heading text-center">{props.title}</p>
          <Row className="d-flex justify-content-center">
            {props.data.map((x, i) => (
              <Col sm={6} md={4} className="d-flex align-items-stretch">
                <Card key={i}>
                  <CardBody>
                    <CardTitle className="d-flex align-items-center">
                      <Image
                        src={x.icon}
                        style={{ maxHeight: "56px" }}
                        className="icon-card-title"
                        alt={x.title}
                      />
                      {x.title}
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    );
  }
  return (
    <section data-aos="fade-right">
      <Container>
        <p className="heading text-center">{props.title}</p>
        <Row>
          {props.data.map((x, i) => (
            <Col md={6} lg={4} className=" d-flex align-items-stretch">
              <Card key={i}>
                <CardBody>
                  <CardTitle className="d-flex align-items-center">
                    <Image
                      src={x.icon}
                      className="icon-card-title"
                      alt={x.title}
                    />
                    {x.title}
                  </CardTitle>
                  <CardText>{x.description}</CardText>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
