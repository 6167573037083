import { Container, Row, Col, Image } from "react-bootstrap";
import "./focus.scss";

export const Focus = () => (
  <section id="focus" className="pb-5" data-aos="fade-up">
    <Container>
      <Row className=" d-flex align-items-center">
        <Col md={8}>
          <p className="heading">Industry Focus</p>
          <p className="grey">
            Our expertise lies in producing attractive products that speak
            volumes in your industry.
          </p>
          <ul>
            <li>Small and medium-sized enterprises (UMKM)</li>
            <li>Start-up company</li>
            <li>Social media content</li>
          </ul>
        </Col>
        <Col md={4}>
          <Image src="/images/industry-focus.png" alt="" fluid />
        </Col>
      </Row>
    </Container>
  </section>
);
